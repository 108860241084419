import { useGlobalStore } from '~/stores/global';

export default defineNuxtRouteMiddleware((to) => {
	if (to.path === '/search' && !to.query.search) {
		const globalStore = useGlobalStore();
		if (globalStore.storeType !== 'OCTANE') {
			return navigateTo('/all-products');
		}
	}
});
